// extracted by mini-css-extract-plugin
export var backTo = "banners-module--backTo--1177c";
export var backToWrapper = "banners-module--backToWrapper--bc9da";
export var check = "banners-module--check--c324a";
export var freeTier = "banners-module--freeTier--7ba3b";
export var helpInfo = "banners-module--helpInfo--2f1aa";
export var hostedVpnBanner = "banners-module--hostedVpnBanner--7fb79";
export var hostedVpnTitle = "banners-module--hostedVpnTitle--0ace7";
export var premiumLink = "banners-module--premiumLink--71810";
export var premiumTier = "banners-module--premiumTier--b51b7";
export var tierLink = "banners-module--tierLink--c8e3f";
export var warning = "banners-module--warning--382ee";