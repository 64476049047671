import React from 'react';
import * as styles from "./banners.module.css";

const BannerTitle = ({children}) => {
    return (
        <div className={styles.hostedVpnTitle}>
            <h1>{children}</h1>
        </div>
    )
}

export default BannerTitle