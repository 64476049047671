import React from 'react';
import * as styles from "./settingsview.module.css";
import Tippy from '@tippyjs/react';
import { FaQuestionCircle } from "react-icons/fa";

function SettingSlice({title, help, children}) {
    return (
        <div className={styles.settingsSlice}>
            <h3 style={{
                textAlign: "center"
            }}>{title}</h3>
            <Tippy content={help}>
                <div className={styles.icon}><span><FaQuestionCircle /></span></div>
            </Tippy>
            {children}
        </div>
    )
}

export default SettingSlice