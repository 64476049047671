import React, { useState } from "react"
import EmailForm from "../../forms/signupform/emailform"
import BigMessage from "../../common/bigmessage/bigmessage"
import SettingSlice from "../settingsview/settingslice"

const PassRecovery = () => {
  const [knownEmail, setKnownEmail] = useState(null);

  const handleEmailSubmit = (email) => {
    setKnownEmail(email);
  }

  const emailForm = () => <SettingSlice title="Send link to reset password" help="Help Me!">
    <EmailForm submitHandler={handleEmailSubmit} />
  </SettingSlice>

  const bigMessage = () => <BigMessage btn={{title: "Sign In", page: "/"}}>You request has been processed.
    If you entered correct information, we will send you a link to reset your password to <strong>{knownEmail}</strong>.
    Please check your mailbox.
  </BigMessage>

  const content = knownEmail ? bigMessage() : emailForm()

  return (
    <div>
      { content }
    </div>
  )
}

export default PassRecovery;