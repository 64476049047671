import React, { useEffect, useState } from "react"
import Seo from "../components/seo"
import SettingsLayout from "../components/layouts/settingslayout"
import SettingSlice from "../components/views/settingsview/settingslice"
import EmailForm from "../components/forms/signupform/emailform"
import util from "../js/util"
import Loader from "../components/common/loader/loader"
import BigMessage from "../components/common/bigmessage/bigmessage"
import PassRecovery from "../components/views/passrecovery/passrecovery"

const ForgotPassword = () => {
    const [authenticated, setAuthenticated] = useState(undefined)
  
    useEffect(() => {
        util.checkNotLogin().then(res => {
            setAuthenticated(!res)
        })
    }, [])

    const settingsSlice = () => <PassRecovery />

    const bigMessage = () => <BigMessage btn={{title: "Settings", page: "/settings"}}>
        You are already authenticated in our systems. If you want to change your password, you can do so by clicking button below.
    </BigMessage>

    const loader = () => <Loader />

    const content = authenticated === undefined ? loader() : authenticated === true ? bigMessage() : settingsSlice()

    return (
      <SettingsLayout title="Restore Password" backAction={() => util.navigateToPage("/")} backTooltip="Back to Login">
          {content}
      </SettingsLayout>
    )
}

export default ForgotPassword

export const Head = () => <Seo title="Password recovery" />