import React from 'react';
import * as styles from "./layout.module.css";
import CommonBanner from '../banners/commonbanner';
import BackLink from '../banners/backlink';

const SettingsLayout = ({title, backAction, backTooltip, children}) => {
    return (
        <div className={styles.settingsView}>
            <CommonBanner title={ title }></CommonBanner>
            <BackLink action={ backAction } tooltip={ backTooltip } />
            { children }
        </div>
    )
}

export default SettingsLayout