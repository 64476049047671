import React from 'react';
import * as styles from "./banners.module.css";
import BannerTitle from './bannertitle';

const CommonBanner = ({children, title}) => {
    return (
        <div className={styles.hostedVpnBanner}>
            <BannerTitle>{title}</BannerTitle>
            {children}
        </div>
    )
}

export default CommonBanner