import React from 'react';
import * as styles from "./banners.module.css";
import { IoMdArrowRoundBack } from "react-icons/io";
import Tippy from '@tippyjs/react';

const BackLink = ({action, tooltip}) => {
    const plainContent = <div className={styles.backTo} onClick={action}>
        <span><IoMdArrowRoundBack /></span>
    </div>

    const advancedContent = <Tippy content={tooltip}>
        { plainContent }
    </Tippy>

    const finalContent = tooltip === undefined ? plainContent : advancedContent
    
    return (
        <div className={styles.backToWrapper}>
            {finalContent}
        </div>
    )
}

export default BackLink