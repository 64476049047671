import React, { useState } from "react"
import * as styles from "../signupform/signupform.module.css";
import ErrorsList from "./errlist"
import apiclient from "../../../js/apiclient"

const EmailForm = ({submitHandler}) => {
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        email: ""
    })
    const handleSubmit = (event) => {
        event.preventDefault()

        apiclient.sendPasswordResetEmail(formData.email)
          .then(response => {
              if (response.status === 200) {
                  submitHandler(formData.email)
              } else {
                  response.json().then(responseErrors => {
                      const rawRespErrors = [...responseErrors]
                      const fieldErrors = rawRespErrors.reduce((acc, error) => {
                          if (acc[error.field] !== undefined) {
                              acc[error.field].push(error.error)
                          } else {
                              acc[error.field] = [error.error]
                          }

                          return acc
                      }, {})
                      console.log(fieldErrors)
                      setErrors(fieldErrors)
                  })
              }
          })
    }

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        delete errors[name]

        const updatedFormData = Object.assign({}, formData)
        updatedFormData[name] = value

        setFormData(updatedFormData)
    }

    return (
        <div>
            <div className={ styles.quicksignup } style={{
                minHeight: "unset",
                border: "unset",
                boxShadow: "unset"
            }}>
                <form onSubmit={ handleSubmit }>
                    <label>
                        <span>*</span> Email:
                        <input
                        name="email"
                        type="email"
                        placeholder="email@exmaple.com"
                        onChange={handleInputChange} />
                        { errors.email !== undefined && <ErrorsList errors={errors.email} />}
                    </label>
                    <input type="submit" value="Submit"/>
                </form>
            </div>
        </div>
    )
}

export default EmailForm