import React from 'react';
import { FaCheck } from "react-icons/fa"
import util from "../../../js/util"

const BigMessage = ({children, btn}) => {
  return (
    <div style={{
      margin: "0 auto",
      textAlign: "center",
      width: "50%"
    }}>
      <div style={{
        color: "green",
        fontSize: "3rem",
        marginBottom: "1rem"
      }}>
        <span><FaCheck /></span>
      </div>
      <div style={{
        fontSize: "1.2rem",
        fontStyle: "italic",
        fontWeight: "500",
        padding: "1rem"
      }}>{ children }</div>
      { btn && <div style={{
        backgroundColor: '#0074d4',
        borderColor: '#0074d4',
        borderRadius: '4px',
        borderStyle: 'none',
        color: '#fff',
        cursor: 'pointer',
        display: 'block',
        fontSize: '1.2rem',
        fontWeight: '600',
        height: '35px',
        lineHeight: '30px',
        margin: '20px auto',
        padding: '.4rem',
        transition: 'background-color .2s',
        minWidth: '200px',
        width: "fit-content"
      }} onClick={() => util.navigateToPage(btn.page)}>{btn.title}</div> }
    </div>
  )
}

export default BigMessage