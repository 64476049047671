import React from 'react';
import Tippy from "@tippyjs/react"
import * as styles from "./signupform.module.css"
import { RiErrorWarningFill } from "react-icons/ri"

const ErrorsList = ({errors}) => {
  const errorsContent = <div>
    <ul style={{
      listStyle: "square"
    }}>
      {errors.map((error, i) => {
        return <li key={i}>{error}</li>
      })}
    </ul>
  </div>

  return (
    <Tippy content={errorsContent}>
      <div className={styles.errorToken}>
        <span><RiErrorWarningFill /></span>
      </div>
    </Tippy>
  )
}

export default ErrorsList